import { createSlice } from "@reduxjs/toolkit"

//Faire la difference entre extension et site
function detectEnvironment() {
	const extensionProtocols = [
		"chrome-extension:",
		"moz-extension:",
		"edge-extension:",
	]

	const currentProtocol = window.location.protocol

	if (extensionProtocols.includes(currentProtocol)) {
		return "extension"
	} else {
		return "site"
	}
}

//Storage de chrome ou firefox
let storageAPI
if (typeof chrome !== "undefined" && chrome.storage && chrome.storage.local) {
	storageAPI = chrome.storage.local
} else if (
	typeof browser !== "undefined" &&
	browser.storage &&
	browser.storage.local
) {
	storageAPI = browser.storage.local
}

//Surplanter le removeItem/SetItem

const originalSetItem = localStorage.setItem
const originalRemoveItem = localStorage.removeItem

localStorage.removeItem = function (key) {
	originalRemoveItem.call(this, key)

	if (storageAPI !== undefined) {
		storageAPI.remove(key)
	}
}

localStorage.setItem = function (key, value) {
	originalSetItem.call(this, key, value)

	if (storageAPI !== undefined) {
		storageAPI.set({ [key]: value })
	}
}

// Pour l'extension, synchroniser le localStorage avec le browserApi.Storage
async function loadLocalStorageFromChrome() {
	if (detectEnvironment() === "site") {
		return
	}

	let storage = null

	if (typeof browser !== "undefined" && browser?.storage) {
		storage = browser.storage
	} else if (typeof chrome !== "undefined" && chrome?.storage) {
		storage = chrome.storage
	}

	if (!storage) {
		return
	}

	const getStorageItems = () => {
		return new Promise((resolve, reject) => {
			if (typeof storage.local.get === "function") {
				console.log("storage.local.get", storage.local.get)
				storage.local.get(null, (items) => {
					resolve(items)
				})
			} else {
				reject("storage.local.get is not a function")
			}
		})
	}

	try {
		const items = await getStorageItems()

		for (const [key, value] of Object.entries(items)) {
			localStorage.setItem(key, value)
		}
	} catch (error) {
		console.error("Error accessing storage:", error)
	}
}

//On doit le faire avant les getItem
await loadLocalStorageFromChrome()

const token = localStorage.getItem("token")
const xLsToken = localStorage.getItem("xLsToken")
const mfaLogged = localStorage.getItem("mfaLogged")

const apiVersion = localStorage.getItem("apiVersion")
const canSendSms = localStorage.getItem("canSendSms")

const authSlice = createSlice({
	name: "auth",
	initialState: {
		token: token ? JSON.parse(token) : null,
		xLsToken: xLsToken ? xLsToken : null,
		mfaLogged: mfaLogged ? mfaLogged : null,
		apiVersion: apiVersion ? apiVersion : undefined,
		appVersion: null,
		canSendSms: canSendSms ? JSON.parse(canSendSms) : undefined,
		logPassed: false,
		loading: false,
		logged: false,
		pinPassed: false, // pinPassed is mandatory to correctly manage the PinForm after session expiration with mfa procedure.
		mLoading: true,
		errorStatus: false,
		showPadlock: false,
		selectedOrg: 0,
		forgotPassMode: false,
		changePassMode: false,
		lastActionResult: null,
	},
	reducers: {
		updateWm: (state, action) => {
			let newToken = { ...state.token }

			newToken.userSession.userWhitemark = {
				...newToken.userSession.userWhitemark,
				...action.payload,
			}

			state.token = newToken
		},
		setSsoInfos: (state, action) => {
			if (!action.payload) state.ssoInfos = null
			else state.ssoInfos = action.payload
		},
		loginLoading: (state, action) => {
			if (!action.payload) state.loading = null
			else state.loading = action.payload
		},
		mLoading: (state, action) => {
			if (!action.payload) state.mLoading = null
			else state.mLoading = action.payload
		},
		setAppVersion: (state, action) => {
			if (!action.payload) state.appVersion = null
			else state.appVersion = action.payload
		},
		setForgotPass: (state, action) => {
			if (!action.payload) state.forgotPassMode = false
			else state.forgotPassMode = true
		},
		setChangePass: (state, action) => {
			if (!action.payload) state.changePassMode = false
			else state.changePassMode = true
		},
		tokenIsValid: (state) => {
			state.loading = false
			state.logPassed = true
			state.mLoading = false
		},
		updateUserConnexionNumber: (state, action) => {
			let newToken = { ...state.token }
			newToken.userSession.connexionNumber = action.payload
			localStorage.setItem("token", JSON.stringify(newToken))
			state.token = newToken
		},
		updateUsername: (state, action) => {
			let newToken = { ...state.token }
			const { firstName, lastName } = action.payload.data
			newToken.userSession.username = `${firstName} ${lastName}`
			localStorage.setItem("token", JSON.stringify(newToken))
			state.token = newToken
		},
		loginSuccess: (state, action) => {
			state.loading = false
			state.mLoading = false
			state.logPassed = true
			state.token = action.data

			if (action.data?.userSession?.userEmail) {
				let lastUsedEmail = localStorage.getItem("userEmail")

				if (action.data?.userSession?.userEmail !== lastUsedEmail) {
					localStorage.removeItem("LPrecentCats")
					localStorage.removeItem("LPrecentPass")
					localStorage.removeItem("LSlastSaw")
				}

				if (
					localStorage.getItem("apiUrl") !==
					localStorage.getItem("oldApiUrl")
				) {
					localStorage.removeItem("LPrecentCats")
					localStorage.removeItem("LPrecentPass")
				}

				localStorage.setItem(
					"userEmail",
					action.data.userSession.userEmail,
				)
			}

			localStorage.setItem("token", JSON.stringify(action.data))
		},
		loginFailed: (state) => {
			state.loading = false
			state.errorStatus = true
		},
		resetLogin: (state) => {
			state.token = null
			state.logPassed = false
			state.logged = false
			state.showPadlock = false
			state.pinPassed = false
			state.mfaPassed = false
			localStorage.removeItem("token")
			localStorage.removeItem("xLsToken")
			localStorage.removeItem("mfaLogged")
			localStorage.removeItem("lastCalledSaga")
			localStorage.removeItem("selectedOrga")
			state.xLsToken = null

			if (typeof browser !== "undefined" && !!browser?.storage) {
				browser.storage.sync.remove("xLsToken")
			} else if (typeof chrome !== "undefined" && !!chrome?.storage) {
				chrome.storage.sync.remove("xLsToken")
			}
		},
		mfaSuccess: (state) => {
			state.logged = true
			state.loading = false
			state.mfaLogged = true
			state.showPadlock = false
			state.pinPassed = false
		},
		mfaFailed: (state) => {
			state.errorStatus = true
			state.loading = false
		},
		pinSuccess: (state, action) => {
			state.loading = false
			state.apiVersion = action.data.res.version
			state.canSendSms = action.data.res.canSendSms
			state.xLsToken = action.data.xLsT
			state.pinPassed = true

			// If the account has MFA, there's still one more step before login.
			if (!state?.token?.userSession?.isMfaConnection) {
				state.showPadlock = false
			}

			state.token.userSession.licenseExpirationDate =
				action.data.res.organisationExpirationDate

			// If the account has MFA, there's still one more step before login.
			if (!state?.token?.userSession?.isMfaConnection) {
				state.logged = true
			}

			const token = JSON.parse(localStorage.getItem("token"))
			const userSession = token.userSession
			userSession.licenseExpirationDate =
				action.data.res.organisationExpirationDate

			localStorage.setItem("token", JSON.stringify(token))
			localStorage.setItem("xLsToken", action.data.xLsT)
			localStorage.setItem("canSendSms", action.data.res.canSendSms)
			localStorage.setItem("apiVersion", action.data.res.version)

			if (typeof browser !== "undefined" && browser?.storage) {
				browser.storage.sync.set({
					xLsToken: action.data.xLsT,
					apiVersion: action.data.res.version,
				})
			} else if (typeof chrome !== "undefined" && chrome?.storage) {
				chrome.storage.sync.set({
					xLsToken: action.data.xLsT,
					apiVersion: action.data.res.version,
				})
			}
		},
		mfaPinSuccess: (state) => {
			state.loading = false
			state.logged = true
		},
		pinFailed: (state) => {
			state.loading = false
			state.errorStatus = true
		},
		resetErrorStatus: (state) => {
			state.errorStatus = false
		},
		showPadlock: (state) => {
			//localStorage.removeItem('apiVersion')
			localStorage.removeItem("canSendSms")
			state.appVersion = undefined
			state.logPassed = true
			state.showPadlock = true
			state.mfaLogged = false
			//state.apiVersion = null
			state.canSendSms = null
			state.loading = false
			state.mLoading = false
			state.pinPassed = false
		},
		sessionExpired: (state, action) => {
			state.token = null
			state.xLsToken = null
			state.mfaLogged = false
			state.logPassed = false
			state.loading = false
			state.mLoading = false
			state.logged = false
			state.pinPassed = false
			state.appVersion = undefined

			localStorage.removeItem("xLsToken")
			localStorage.removeItem("token")
			localStorage.removeItem("mfaLogged")
			//localStorage.removeItem('apiVersion')
			localStorage.removeItem("canSendSms")

			localStorage.removeItem("lastCalledSaga")
			localStorage.removeItem("selectedOrga")
			localStorage.removeItem("options")

			if (typeof browser !== "undefined" && !!browser?.storage) {
				browser.storage.sync.remove("xLsToken")
			} else if (typeof chrome !== "undefined" && !!chrome?.storage) {
				chrome.storage.sync.remove("xLsToken")
			}

			/*try {
				JSON.parse(action.payload.noReload)
			} catch (e) {
				window.location.reload(false)
			}*/
			//alert()
			if (action.payload?.noReload) {
				setTimeout(() => localStorage.removeItem("error"), 2000)
				setTimeout(() => localStorage.removeItem("warning"), 2000)
				setTimeout(() => localStorage.removeItem("success"), 2000)
				return
			} else {
				return window.location.reload(false)
			}
		},
		sessionRetrieved: (state) => {
			/*state.xLsToken = action.payload.xLsT
			state.token = action.payload.token*/
			state.mLoading = false
			state.logged = true
			state.logPassed = true
			setTimeout(() => localStorage.removeItem("error"), 2000)
			setTimeout(() => localStorage.removeItem("success"), 2000)
		},
		mfaSessionRetrieved: (state) => {
			/*state.xLsToken = action.payload.xLsT
			state.token = action.payload.token*/
			state.mLoading = false
			state.logged = true
			state.logPassed = true
			state.mfaLogged = true
			state.showPadlock = false
			setTimeout(() => localStorage.removeItem("error"), 2000)
			setTimeout(() => localStorage.removeItem("success"), 2000)
		},
		lockSession: (state) => {
			//state.apiVersion = undefined
			state.canSendSms = undefined
			state.xLsToken = undefined
			state.logged = false
			state.pinPassed = false
			state.mfaLogged = false
			state.appVersion = undefined
			state.mfaPassed = false

			localStorage.removeItem("canSendSms")
			localStorage.removeItem("user")
			//localStorage.removeItem("apiVersion")
			localStorage.removeItem("xLsToken")
			localStorage.removeItem("mfaLogged")

			if (typeof browser !== "undefined" && !!browser?.storage) {
				browser.storage.sync.remove("xLsToken")
			} else if (typeof chrome !== "undefined" && !!chrome?.storage) {
				chrome.storage.sync.remove("xLsToken")
			}
		},
		changeSelectedOrg: (state, action) => {
			state.selectedOrg = action.payload.val
			localStorage.setItem("selectedOrga", action.payload.val)

			localStorage.removeItem("LPrecentCats")
			localStorage.removeItem("LPrecentPass")

			if (action.payload.reload) window.location.reload(false)
		},
		saveUserType: (state, action) => {
			const userDataInLC = JSON.parse(localStorage.getItem("user"))
			const tokenDataInLC = JSON.parse(localStorage.getItem("token"))

			switch (true) {
				case action.payload.isAdmin:
					userDataInLC.userType = "isAdmin"
					tokenDataInLC.userSession.userType = "isAdmin"
					state.token.userSession.userType = "isAdmin"
					break
				case action.payload.isModerator:
					userDataInLC.userType = "isModerator"
					tokenDataInLC.userSession.userType = "isModerator"
					state.token.userSession.userType = "isModerator"
					break
				case action.payload.isFarmer:
					userDataInLC.userType = "isFarmer"
					tokenDataInLC.userSession.userType = "isFarmer"
					state.token.userSession.userType = "isFarmer"
					break

				default:
					userDataInLC.userType = "isUser"
					tokenDataInLC.userSession.userType = "isUser"
					state.token.userSession.userType = "isUser"
					break
			}

			localStorage.setItem("user", JSON.stringify(userDataInLC))
			localStorage.setItem("token", JSON.stringify(tokenDataInLC))
		},
		devSetTokenExpirationDate: (state, action) => {
			state.token.userSession.licenseExpirationDate = action.payload
		},
		devToggleFirstCo: (state) => {
			state.token.userSession.connexionNumber = 1
		},
		devTogglePoc: (state, action) => {
			state.token.userSession.isPocLicense = action.payload
		},
		setMfaConnection: (state, action) => {
			state.token.userSession.isMfaConnection = action.payload
		},
		setLAR: (state, action) => {
			if (!action.payload) state.lastActionResult = null
			else state.lastActionResult = action.payload
		},
		removeXLsT: (state) => {
			state.xLsToken = null
		},
	},
})

export const {
	login,
	loginSuccess,
	loginFailed,
	resetLogin,
	pinSuccess,
	pinFailed,
	sessionExpired,
	sessionRetrieved,
} = authSlice.actions

export default authSlice.reducer

import { toastr } from "react-redux-toastr"
import I18n from "../../i18n"
import {
	handleError,
	handleNetworkError,
	getExternalUrl,
	backwardCompApi,
} from "../../actions/generalUtils"

export const getApiUrl = (payload) => {
	let responseStatus
	const url = getExternalUrl() + "url-api?pattern=" + payload

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"Accept-Language": localStorage.getItem("i18nextLng"),
		},
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) =>
			!responseStatus
				? toastr.error(I18n.t("forServices.error"), res.message)
				: res,
		) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const login = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "login_check"

	return fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Accept-Language": localStorage.getItem("i18nextLng"),
		},
		body: JSON.stringify({
			email: payload.email,
			password: payload.pass,
		}),
	})
		.catch((e) => {
			if (e.message === "Failed to fetch") {
				localStorage.removeItem("apiUrl")
				localStorage.removeItem("oldApiUrl")
				localStorage.removeItem("xLsToken")
				localStorage.removeItem("token")
				localStorage.removeItem("user")

				if (typeof browser !== "undefined" && !!browser?.storage) {
					browser.storage.sync.remove("xLsToken")
				} else if (typeof chrome !== "undefined" && !!chrome?.storage) {
					chrome.storage.sync.remove("xLsToken")
				}

				window.location.reload(true)
			}
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			handleNetworkError()
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) =>
			!responseStatus
				? toastr.error(I18n.t("forServices.error"), res.message)
				: res,
		) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const decrypt = (payload) => {
	let responseStatus
	let xLsToken = ""
	let url = localStorage.getItem("apiUrl") + "decrypts/accounts"

	return fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Accept-Language": localStorage.getItem("i18nextLng"),
			Authorization: "Bearer " + payload.token,
		},
		body: JSON.stringify({
			id: payload.id,
			auth: payload.auth,
			pin: payload.pin,
			endPin: payload.dura * 60,
		}),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			handleNetworkError()
			window.location.replace("/")
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			for (let pair of res.headers.entries()) {
				if (pair[0] === "x-ls-token") xLsToken = pair[1]
			} // On récupère le xLsToken depuis les headers
			return res
		})
		.then((res) => res.json())
		.then((res) =>
			!responseStatus ? handleError(res) : { res: res, xLsT: xLsToken },
		) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const isAlive = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "users/alive"

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"Accept-Language": localStorage.getItem("i18nextLng"),
			"X-Ls-Token": payload.xLsT,
			Authorization: "Bearer " + payload.token.token,
		},
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			// handleNetworkError()
			// Object.keys(window.location).forEach(item => console.log(item, window.location[item]))

			// if (window.location.pathname !== "/") window.location.replace("/")
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => (!responseStatus ? res.json() : res))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const isValidToken = (payload) => {
	let url = localStorage.getItem("apiUrl") + "users/token"

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"Accept-Language": localStorage.getItem("i18nextLng"),
			Authorization: "Bearer " + payload.token.token,
		},
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => res)
}

export const logOut = (payload) => {
	let url = localStorage.getItem("apiUrl") + "users/disconnect"

	fetch(url, {
		method: "DELETE",
		headers: {
			"Accept-Language": localStorage.getItem("i18nextLng"),
			Authorization: "Bearer " + payload.token,
			"X-Ls-Token": payload.xLsT,
		},
	})

	return new Promise((resolve) => resolve())
}

export const getCatList = (payload, xLsT) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "categories/mains"

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"Accept-Language": localStorage.getItem("i18nextLng"),
			"X-LS-Token": xLsT,
			Authorization: "Bearer " + payload.token,
		},
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => (responseStatus ? res.json() : res))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const forgotPass = (payload) => {
	let url =
		localStorage.getItem("apiUrl") + "external/users/password/forgotten"

	// const emailToSend = encodeURIComponent(payload.email) A REMETTRE QUAND LE BACK SERA BON -> DECOMMENTER ICI ET REMETTRE LE BON BODY

	return fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Accept-Language": localStorage.getItem("i18nextLng"),
		},
		// body: JSON.stringify({ email: emailToSend })
		body: JSON.stringify(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			return res
		})
}

export const checkChangePass = (payload) => {
	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		`external/users/password/change?email=${encodeURIComponent(
			payload.email,
		)}&hash1=${payload.hash1}&hash2=${payload.hash2}`

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"Accept-Language": localStorage.getItem("i18nextLng"),
		},
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => (responseStatus ? "Correct" : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res))
}

export const resetPass = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + `external/users/password/change`

	return fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Accept-Language": localStorage.getItem("i18nextLng"),
		},
		body: JSON.stringify(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => (responseStatus ? "Correct" : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const requestSSO = async (payload) => {
	let responseStatus
	let networkError = false
	let apiUrl = localStorage.getItem("apiUrl").replace("api/", "")
	let url =
		localStorage.getItem("apiUrl").replace("api/", "") +
		`saml2/login?uuid=${payload.uuid}&email=${payload.email}`

	if (window.localStorage.getItem("apiVersion") === null) {
		await fetch(apiUrl?.replace("/api/", "") + "/version.json", {
			method: "GET",
		})
			.then((res) => res.json())
			.then((res) => {
				localStorage.setItem("apiVersion", res.version)
			})
			.catch(() => {})
	}

	const headers = {
		"Content-Type": "application/json",
		"Accept-Language": localStorage.getItem("i18nextLng"),
	}
	if (backwardCompApi("1.25.7")) {
		headers.LSOrigin = window.location.href
	}

	return fetch(url, {
		method: "GET",
		headers,
	})
		.catch((e) => {
			networkError = true
			if (e.message === "Failed to fetch") {
				localStorage.removeItem("apiUrl")
				localStorage.removeItem("oldApiUrl")
				localStorage.removeItem("xLsToken")
				localStorage.removeItem("token")
				localStorage.removeItem("user")

				if (typeof browser !== "undefined" && !!browser?.storage) {
					browser.storage.sync.remove("xLsToken")
				} else if (typeof chrome !== "undefined" && !!chrome?.storage) {
					chrome.storage.sync.remove("xLsToken")
				}

				window.location.reload(true)
			}
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
		})
		.then((res) => {
			if (networkError) {
				return
			}
			responseStatus = res.ok
			return res
		})
		.then((res) => {
			if (networkError) {
				return true
			}
			return res.json().catch(() => res)
		})
		.then((res) => (!responseStatus ? null : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const samlCheck = (payload) => {
	let responseStatus
	let url =
		localStorage.getItem("apiUrl").replace("api/", "") + "saml2/connect"
	let networkError = false

	return fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Accept-Language": localStorage.getItem("i18nextLng"),
		},
		body: JSON.stringify({
			...payload,
		}),
	})
		.catch(() => {
			networkError = true
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
		})
		.then((res) => {
			if (networkError) {
				return
			}
			responseStatus = res.ok
			return res
		})
		.then((res) => {
			if (networkError) {
				return
			}
			return res.json().catch(() => res)
		})
		.then((res) => {
			if (!responseStatus) {
				handleError(res)
				return { ...res, error: true }
			} else {
				return res
			}
		})
}

export const registerSsoAccount = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "validate/account/pin"

	return fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Accept-Language": localStorage.getItem("i18nextLng"),
		},
		body: JSON.stringify(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) =>
			!responseStatus
				? toastr.error(I18n.t("forServices.error"), res.message)
				: res,
		) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

// Récupère les informations de la whitemark externe, lancé à la connexion.
export const getExternalWhitemark = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "whitemarks/external" // + formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"Accept-Language": localStorage.getItem("i18nextLng"),
			Authorization: "Bearer " + payload.token,
			"X-Ls-Token": payload.xLsT,
		},
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res, false) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getOptions = (payload) => {
	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		`organizations/${payload.organizationId}/options`

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"Accept-Language": localStorage.getItem("i18nextLng"),
			Authorization: "Bearer " + payload.token,
			"X-Ls-Token": payload.xLsT,
		},
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res, false) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

// Valide l'authentification à deux facteurs à l'aide de Google Auth.
export const mfaConnect = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "connection/user/otp"
	const requestPayload = {
		secureCode: payload.secureCode,
		userId: payload.userId,
	}

	return fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Accept-Language": localStorage.getItem("i18nextLng"),
			Authorization: "Bearer " + payload.token,
			"X-Ls-Token": payload.xLsT,
		},
		body: JSON.stringify(requestPayload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => {
			if (!responseStatus) {
				if (res.code === 403) return "invalidCode"
				handleError(res)
				return false
			}

			return res
		}) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

// Valide l'authentification à deux facteurs en utilisant un code de récupération.
// Nécessite le code PIN de l'utilisateur
export const mfaBackupConnect = (payload) => {
	let url = localStorage.getItem("apiUrl") + "connection/user/recovery_code"
	const requestPayload = {
		secureCode: payload.secureCode,
		pin: payload.pin,
	}
	let operationSuccess

	return fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Accept-Language": localStorage.getItem("i18nextLng"),
			Authorization: "Bearer " + payload.token,
			"X-Ls-Token": payload.xLsT,
		},
		body: JSON.stringify(requestPayload),
	})
		.catch(() => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			return Promise.reject(true)
		})
		.then((res) => {
			operationSuccess = res.ok
			return res.json()
		})
		.then((res) => {
			if (!operationSuccess) {
				handleError(res)
			}

			return res
		}) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

import { toastr } from "react-redux-toastr"
import I18n from "../../i18n"
import { handleError, sleep } from "../../actions/generalUtils"

//import { saveAs } from 'file-saver'

function headers(payload) {
	let a = {
		"Accept-Language": localStorage.getItem("i18nextLng"),
		Authorization: "Bearer " + payload.token,
		"X-LS-Token": payload.xLsT,
	}

	if (!payload.formData) a = { ...a, "Content-Type": "application/json" }

	return a
}

function formQuery(d) {
	let q = ""
	let first = true

	if (d.offset) {
		first = false
		q += "?offset=" + d.offset
	}

	if (d.limit) {
		if (first) q += "?limit=" + d.limit
		else q += "&limit=" + d.limit
		first = false
	}

	if (d.search) {
		if (first) q += "?search=" + d.search
		else q += "&search=" + d.search
		first = false
	}

	return q
}

export const getActiveTransfers = (payload) => {
	////console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") + "transfers/active" + formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getArchiveTransfers = (payload) => {
	////console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		"transfers/archive" +
		formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const postPasswordTransfer = (payload, retries = 0) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "transfers/password"

	const data = new FormData()
	data.append("ackReceipt", payload.data.ackReceipt)
	data.append("chunkIndex", payload.data.chunkIndex)
	data.append("description", payload.data.description)
	data.append("emails", payload.data.emails)
	data.append("expirationDate", payload.data.expirationDate)
	data.append("file", payload.data.filePart)
	data.append("maxDl", payload.data.maxDl)
	data.append("password", payload.data.password)
	data.append("phones", payload.data.phones)
	data.append("totalChunkCount", payload.data.totalChunkCount)
	data.append("totalSize", payload.data.totalSize)
	data.append("uuid", payload.data.uuid)
	data.append("masterId", payload.data.masterId)
	data.append("sendEmail", payload.data.sendEmail)
	data.append("firstUpload", payload.data.firstUpload)

	return fetch(url, {
		method: "POST",
		headers: headers({ ...payload, formData: true }),
		body: data,
	})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => {
			const feedbackForSaga = { ...res }
			if (retries > 0) feedbackForSaga.uncertainChunk = true

			if (responseStatus) {
				return feedbackForSaga
			}

			// If we have a 409, we must simply proceed the next chunk, hence the return without handleError despite
			// the code designing an error in the payload.
			if (res.code === 409) return feedbackForSaga

			return handleError(res)
		}) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
		.catch((e) => {
			if (retries < 5) {
				toastr.error(
					I18n.t("forServices.networkError"),
					I18n.t("forServices.triesLeft", { tryNumber: retries + 1 }),
				)
				return sleep(5000).then(() =>
					postPasswordTransfer(payload, retries + 1),
				)
			}

			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			return handleError(e)
		})
}

export const postEmailTransfer = (payload, retries = 0) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "transfers/email"

	const data = new FormData()
	data.append("ackReceipt", payload.data.ackReceipt)
	data.append("chunkIndex", payload.data.chunkIndex)
	data.append("description", payload.data.description)
	data.append("emails", payload.data.emails)
	data.append("expirationDate", payload.data.expirationDate)
	data.append("file", payload.data.filePart)
	data.append("totalChunkCount", payload.data.totalChunkCount)
	data.append("totalSize", payload.data.totalSize)
	data.append("uuid", payload.data.uuid)
	data.append("masterId", payload.data.masterId)
	data.append("sendEmail", payload.data.sendEmail)
	data.append("firstUpload", payload.data.firstUpload)

	////console.log('formdata = ', ...data)

	return fetch(url, {
		method: "POST",
		headers: headers({ ...payload, formData: true }),
		body: data,
	})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => {
			const feedbackForSaga = { ...res }
			if (retries > 0) feedbackForSaga.uncertainChunk = true

			if (responseStatus) {
				return feedbackForSaga
			}

			// If we have a 409, we must simply proceed the next chunk, hence the return without handleError despite
			// the code designing an error in the payload.
			if (res.code === 409) return feedbackForSaga

			return handleError(res)
		}) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
		.catch((e) => {
			if (retries < 5) {
				toastr.error(
					I18n.t("forServices.networkError"),
					I18n.t("forServices.triesLeft", { tryNumber: retries + 1 }),
				)
				return sleep(5000).then(() =>
					postEmailTransfer(payload, retries + 1),
				)
			}

			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			return handleError(e)
		})
}

export const putTransferStatus = (payload) => {
	////console.log(payload)

	let target
	if (payload.type === 0) target = "password"
	else target = "email"

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		"transfers/" +
		target +
		`/${payload.id}`

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify({
			status: payload.status,
			expirationDate: payload.expirationDate,
			ackReceipt: payload.ackReceipt,
			maxDl: payload.maxDl,
		}),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const shareByEmailTransfer = (payload) => {
	////console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "transfers/password/resend/email"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const shareBySMSTransfer = (payload) => {
	////console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") + "transfers/password/resend/password"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const deleteTransfer = (payload) => {
	////console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + `transfers?ids=${payload.action}`

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const addReceipts = (payload) => {
	//console.log(payload)

	let uri
	switch (payload.data.type) {
		case "deposit":
			uri = "depositboxes/user"
			break
		case "transfer":
			uri = "transfers/email/user"
			break
		default:
			return undefined
	}

	let responseStatus
	let url = localStorage.getItem("apiUrl") + uri

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const resendToReceipts = (payload) => {
	//console.log(payload)

	let uri
	switch (payload.data.type) {
		case "deposit":
			uri = "depositboxes/resend/email"
			break
		case "transfer":
			uri = "transfers/email/resend/email"
			break
		default:
			return undefined
	}

	let responseStatus
	let url = localStorage.getItem("apiUrl") + uri

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const removeReceipt = (payload) => {
	//console.log(payload)

	let uri
	switch (payload.data.type) {
		case "deposit":
			uri = `depositboxes/user?isInternal=${payload.data.isInternal}&userIds=${payload.data.ids}&depositBoxId=${payload.data.id}`
			break
		case "transfer":
			uri = `transfers/email/user?ids=${payload.data.ids}`
			break
		default:
			return undefined
	}

	let responseStatus
	let url = localStorage.getItem("apiUrl") + uri

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) =>
			payload.data.type === "transfer"
				? responseStatus
					? res
					: res.json()
				: res.json(),
		)
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

// Change an already existing receipt rights.
export const changeReceiptRights = (payload) => {
	// IMPORTANT : This function was made ONLY FOR DEPOSIT BOXES, if for any reason you have to use it for transfers, please provide the url conditionnally !
	const url =
		localStorage.getItem("apiUrl") +
		`depositboxes/rights/${payload.data.depositBoxId}`
	let responseStatus

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
			return true
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) =>
			payload.data.type === "transfer"
				? responseStatus
					? res
					: res.json()
				: res.json(),
		)
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas. Tu as l'impression d'avoir déjà lu ce texte quelques dizaines de fois ? C'est normal ! Vive les copier/coller !
}

export const getActiveDeposits = (payload) => {
	////console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		"depositboxes/active" +
		formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getArchiveDeposits = (payload) => {
	////console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		"depositboxes/archive" +
		formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const postDeposit = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "depositboxes"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.action),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const postOpenDeposit = (payload) => {
	// console.log("payload", payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "depositboxes/open"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.action),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const addFilesToDeposit = (payload, retries = 0) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "depositboxes/file"

	const data = new FormData()
	data.append("chunkIndex", payload.data.chunkIndex)
	data.append("description", payload.data.description)
	data.append("file", payload.data.filePart)
	data.append("totalChunkCount", payload.data.totalChunkCount)
	data.append("totalSize", payload.data.totalSize)
	data.append("uuid", payload.data.uuid)
	data.append("depositBoxId", payload.data.id)
	data.append("description", payload.data.desc)
	data.append("sendEmail", payload.data.sendEmail)
	data.append("firstUpload", payload.data.firstUpload)

	//console.log('formdata = ', ...data)

	return fetch(url, {
		method: "POST",
		headers: headers({ ...payload, formData: true }),
		body: data,
	})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => {
			const feedbackForSaga = { ...res }
			if (retries > 0) feedbackForSaga.uncertainChunk = true

			if (responseStatus) {
				return feedbackForSaga
			}

			// If we have a 409, we must simply proceed the next chunk, hence the return without handleError despite
			// the code designing an error in the payload.
			if (res.code === 409) return feedbackForSaga

			return handleError(res)
		}) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
		.catch((e) => {
			if (retries < 5) {
				toastr.error(
					I18n.t("forServices.networkError"),
					I18n.t("forServices.triesLeft", { tryNumber: retries + 1 }),
				)
				return sleep(5000).then(() =>
					addFilesToDeposit(payload, retries + 1),
				)
			}

			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			return handleError(e)
		})
}

export const deleteFileFromDeposit = (payload) => {
	////console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		`depositboxes/file?ids=${payload.action.ids}`

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const putDeposit = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "depositboxes/" + payload.id

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify({ ...payload.data }),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getSharedDeposits = (payload) => {
	////console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		"depositboxes/shared" +
		formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const deleteDeposit = (payload) => {
	////console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") + `depositboxes?ids=${payload.action}`

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const downloadFileFromDeposit = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + `download/generate/token`

	const requestOptions = {
		method: "POST",
		headers: headers(payload),
		body: payload.action.multi
			? JSON.stringify({
					method: "downloadDbFilesCaseByCase",
					depositBoxId: payload.action.depositId,
					fileIds: payload.action.ids,
			  })
			: JSON.stringify({
					method: "downloadInternalDbFile",
					depositFileHash1: payload.action.hash1,
					depositFileHash2: payload.action.hash2,
					depositFileId: payload.action.fileId,
			  }),
	}

	function finalDownload(link, res) {
		window.location.assign(link)
		return res
	}

	return fetch(url, requestOptions)
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then(
			(res) => (
				!responseStatus
					? handleError(res)
					: finalDownload(res.link, res),
				res
			),
		)
}

export const getContacts = (payload) => {
	////console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "contacts" + formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const postContact = (payload) => {
	////console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "contacts"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.action),
	})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
}

export const putContact = (payload) => {
	////console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "contacts/" + payload.action.id

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify(payload.action),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const deleteContact = (payload) => {
	////console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + `contacts?ids=${payload.action}`

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

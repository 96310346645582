import {
	call,
	put,
	takeLeading,
	takeEvery,
	actionChannel,
	take,
	select,
} from "redux-saga/effects"
import * as Api from "./services"
import { toastr } from "react-redux-toastr"
import {
	addLCS,
	removeLCS,
	generateFileHashWithoutContent,
	backwardCompApi,
} from "../../actions/generalUtils"
import I18n from "../../i18n"

import { v4 as uuidv4 } from "uuid"

export function* getActiveTransfers(action) {
	try {
		////console.log(action)
		//yield put({ type: "transfer/setLAR"})
		yield put({ type: "transfer/setCR", payload: true })
		addLCS(action)

		const ogP = { ...action.payload }
		let transferState = yield select((state) => state.transfer)

		if (transferState.searchVal.charAt(0) === "#") {
			transferState = {
				...transferState,
				searchVal: transferState.searchVal.substring(1),
			}
		}

		if (!ogP.search) {
			if (
				transferState.pageSize &&
				!action.payload.limit &&
				action.payload.static
			)
				ogP.limit = transferState.pageSize * 2
			else if (ogP.justRefresh)
				ogP.limit = transferState.pagin.page * transferState.pagin.limit
			//s.data[0].files.length
			else ogP.limit = transferState.pageSize
		}

		if (!ogP?.justRefresh && !ogP?.offset)
			yield put({ type: "transfer/toggleLoading" })
		if (ogP.offset)
			yield put({ type: "transfer/paginLoading", payload: true })

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = {
			token,
			xLsT,
			offset: ogP.offset,
			search: transferState.searchVal
				? transferState.searchVal
				: ogP.search,
			limit: ogP.limit,
		}

		const ogData = yield call(Api.getActiveTransfers, payload)

		if (!ogP?.justRefresh && !ogP?.offset)
			yield put({ type: "transfer/toggleLoading" })
		if (ogP.offset) yield put({ type: "transfer/paginLoading" })

		switch (ogData) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				let data = { ...ogData }
				if (
					transferState.pageSize &&
					(!action.payload.limit || ogP.justRefresh)
				) {
					let sps = transferState.pageSize
					data.pages = Math.ceil(ogData.total / sps)
					data.page = Math.floor(ogP.limit / sps)
					data.limit = sps
				}

				if (ogP.offset && !ogP.justRefresh)
					yield put({
						type: "transfer/addElements",
						payload: { ...ogP, ...data },
					})
				else if (ogP.search?.length >= 0)
					yield put({
						type: "transfer/initSearch",
						payload: { ...ogP, ...data },
					})
				else
					yield put({
						type: "transfer/enterInFolder",
						payload: { ...ogP, ...data },
					})

				return yield put({ type: "transfer/setCR" })
			}
		}
	} catch (error) {
		// console.log(error)
	}
}

export function* getArchiveTransfers(action) {
	try {
		addLCS(action)
		yield put({ type: "transfer/setCR", payload: true })
		//yield put({ type: "transfer/setLAR"})
		if (!action.payload?.justRefresh && !action.payload?.offset)
			yield put({ type: "transfer/toggleLoading" })
		if (action.payload.offset)
			yield put({ type: "transfer/paginLoading", payload: true })

		const ogP = { ...action.payload }
		let s = yield select((state) => state.transfer)

		if (s.pageSize && !action.payload.limit && action.payload.static)
			ogP.limit = s.pageSize * 2
		else if (ogP.justRefresh) ogP.limit = s.data[0].files.length
		else ogP.limit = s.pageSize

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = {
			token,
			xLsT,
			offset: ogP.offset,
			search: ogP.search,
			limit: ogP.limit,
		}

		const ogData = yield call(Api.getArchiveTransfers, payload)
		if (ogP.offset) yield put({ type: "transfer/paginLoading" })

		switch (ogData) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				let data = { ...ogData }
				if (s.pageSize && !action.payload.limit) {
					let sps = s.pageSize
					data.pages = Math.ceil(ogData.total / sps)
					data.page = Math.floor(ogP.limit / sps)
					data.limit = sps
				}

				if (ogP.offset)
					yield put({
						type: "transfer/addElements",
						payload: { ...data, ...ogP },
					})
				else if (ogP.search?.length >= 0)
					yield put({
						type: "transfer/initSearch",
						payload: { ...data, ...ogP },
					})
				else
					yield put({
						type: "transfer/enterInFolder",
						payload: { ...data, ...ogP },
					})

				yield put({ type: "transfer/setCR" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* postTransfer(action) {
	try {
		const sagaPayload = { ...action.payload }
		const isLastFile = action.payload.isLast

		const token = yield select((state) => state.auth.token.token)
		const xLsT = yield select((state) => state.auth.xLsToken)
		let lar = yield select((state) => state.transfer.lastActionResult)

		let chunkSize = 20000000 // bytes
		const payload = { token, xLsT }

		sagaPayload.chunkIndex = 0
		sagaPayload.totalChunkCount = Math.ceil(
			sagaPayload.file.size / chunkSize,
		)
		sagaPayload.uuid = uuidv4()

		sagaPayload.masterId = parseInt(
			lar?.message === "FirstCreatedTransferId" && lar?.data
				? lar.data
				: 0,
		)

		let ret = true
		let payData = action.payload

		const uploadBlocked = yield select(
			(state) => state.transfer.stopFilesUpload,
		)

		if (uploadBlocked) {
			sagaPayload.chunkIndex = sagaPayload.totalChunkCount
			ret = true
		}

		const fileId = yield call(
			generateFileHashWithoutContent,
			sagaPayload.file,
		)

		while (sagaPayload.chunkIndex < sagaPayload.totalChunkCount) {
			// If the upload was blocked (this happens when the token has already expired when making
			// the first request), don't do anything except restauring the blocked state if we're
			// treating the last file of the upload, we don't want the user to stay blocked.
			const uploadBlocked = yield select(
				(state) => state.transfer.stopFilesUpload,
			)
			if (uploadBlocked) {
				if (isLastFile) {
					yield put({ type: "action/setActionLoader" })
					yield put({ type: "transfer/setLoader" })
					yield put({ type: "transfer/unblockFilesUpload" })
				}

				return
			}

			let offset = sagaPayload.chunkIndex * chunkSize
			let blob = sagaPayload.file.slice(offset, offset + chunkSize)
			sagaPayload.filePart = new File([blob], sagaPayload.file.name)

			yield put({
				type: "action/startUpload",
				payload: { fileId },
			})

			if (payData.type === 0)
				ret = yield call(Api.postPasswordTransfer, {
					...payload,
					data: {
						...sagaPayload,
						firstUpload:
							sagaPayload.multiPart === 0 ? "true" : "false",
					},
				})
			else
				ret = yield call(Api.postEmailTransfer, {
					...payload,
					data: {
						...sagaPayload,
						firstUpload:
							sagaPayload.multiPart === 0 ? "true" : "false",
					},
				})

			yield put({
				type: "action/endUpload",
				payload: { fileId },
			})

			const checkData =
				ret !== "sessionExpired" &&
				ret !== "sessionDestroyed" &&
				ret !== true

			if (checkData) {
				if (ret.uncertainChunk) {
					yield put({
						type: "transfer/addUncertainFile",
						payload: sagaPayload.file.name,
					})
				}
			} else {
				yield put({ type: "transfer/blockFilesUpload" })
				sagaPayload.chunkIndex = sagaPayload.totalChunkCount
			}

			yield put({ type: "action/closeAction" })

			sagaPayload.chunkIndex++
		}

		if (isLastFile) {
			yield put({ type: "action/setActionLoader" })
			yield put({ type: "transfer/setLoader" })

			// At the last file, unblock the files upload to allow the user other tries at uploading.
			yield put({ type: "transfer/unblockFilesUpload" })
		}

		switch (ret) {
			case "sessionExpired":
				yield put({ type: "action/cancelUploadEstimation" })
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				yield put({ type: "action/cancelUploadEstimation" })
				return yield put({ type: "auth/sessionExpired" })
			case true: {
				yield put({ type: "action/cancelUploadEstimation" })
				yield put({
					type: "transfer/setLAR",
					payload: { message: "wrongEmail", data: false },
				})
				return removeLCS(action)
			}
			default: {
				removeLCS(action)

				if (action.payload.isLast) {
					yield put({ type: "action/closeAction" })
					yield put({ type: "transfer/setUploadLoading" })
					yield put({ type: "transfer/setLAR" })

					let comp = yield select(
						(state) => state.transfer.data?.[0]?.comp,
					)
					//let pagin = yield select(state => state.transfer.pagin)

					if (comp === "Transfer")
						yield put({
							type: "LT_GET_ACTIVE_TRANSFERS_SAGA",
							payload: { justRefresh: true },
						})

					const uncertainFiles = yield select(
						(state) => state.transfer.uncertainFiles,
					)
					if (uncertainFiles) {
						toastr.success(I18n.t("transfer.aTransfer.successWarn"))
						return yield put({
							type: "transfer/removeUncertainFiles",
						})
					} else {
						return toastr.success(
							I18n.t("transfer.aTransfer.success"),
						)
					}
				} else if (action.payload.isFirst) {
					return yield put({
						type: "transfer/setLAR",
						payload: {
							message: "FirstCreatedTransferId",
							data: ret.id,
						},
					})
				} else return
			}
		}
	} catch (error) {
		// console.log(
		// 	"error in postTransfer saga",
		// 	error,
		// )
	}
}

export function* putTransferStatus(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})
		//yield put({ type: "transfer/setLAR"})

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)

		const payload = { token, xLsT, ...action.payload }
		const data = yield call(Api.putTransferStatus, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				toastr.success(I18n.t("editSuccess"))
				yield put({ type: "action/closeAction" })
				yield put({ type: "transfer/updateActive", payload: data })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* sharePwTransfer(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})
		//yield put({ type: "transfer/setLAR"})

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)

		const payload = { token, xLsT, ...action.payload }
		let data = {}

		if (action.payload.type === "email")
			data = yield call(Api.shareByEmailTransfer, payload)
		else if (action.payload.type === "sms")
			data = yield call(Api.shareBySMSTransfer, payload) //payload.data.phones = normalizePhoneNums(payload.data.phones)

		yield put({ type: "action/setActionLoader" })
		yield put({
			type: "LT_GET_ACTIVE_TRANSFERS_SAGA",
			payload: { justRefresh: true },
		})

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				toastr.success(I18n.t("transfer.share.success"))
				yield put({ type: "action/closeAction" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* deleteTransfer(action) {
	try {
		addLCS(action)

		yield put({ type: "transfer/setCR", payload: true })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = { token, xLsT, action: action.payload.getIds }

		const data = yield call(Api.deleteTransfer, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				yield put({ type: "transfer/setCR" })
				return removeLCS(action)
			default: {
				removeLCS(action)

				let comp = yield select(
					(state) => state.transfer.data?.[0]?.comp,
				)

				if (action.payload.getIdsOnArray.length > 1) {
					toastr.success(I18n.t("transfer.delTransSuccessMultiple"))
				} else {
					toastr.success(I18n.t("transfer.delTransfSuccess"))
				}

				yield put({ type: "action/setLAR", payload: "navigateBack" })

				if (
					comp === "Transfer" &&
					action?.requiresRedirectInWindowHistoryState
				) {
					yield put({
						type: "LT_GET_ACTIVE_TRANSFERS_SAGA",
						payload: { justRefresh: true },
					})
				} else if (!action?.requiresRedirectInWindowHistoryState) {
					yield put({ type: "transfer/setCR" })
				}

				return yield put({ type: "action/closeMiniAction" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* addReceipts(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})
		yield put({ type: "transfer/setLAR" })

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)

		const payload = { token, xLsT, data: action.payload }

		let data = yield call(Api.addReceipts, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true: {
				removeLCS(action)
				return yield put({
					type: "transfer/setLAR",
					payload: { message: "error" },
				})
			}
			default: {
				removeLCS(action)

				toastr.success(I18n.t("transfer.editRcpt.success"))
				yield put({
					type: "transfer/setLAR",
					payload: { message: "success" },
				})
				return yield put({
					type: "transfer/updateActive",
					payload: data,
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* resendToReceipts(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})
		//yield put({ type: "transfer/setLAR"})

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)

		const payload = { token, xLsT, data: action.payload }

		let data = yield call(Api.resendToReceipts, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				//yield put({ type: 'action/closeAction' })
				return toastr.success(I18n.t("transfer.editRcpt.resended"))
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* removeReceipt(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})
		yield put({ type: "transfer/setLAR" })

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)

		const payload = { token, xLsT, data: action.payload }

		const data = yield call(Api.removeReceipt, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true: {
				removeLCS(action)
				return yield put({
					type: "transfer/setLAR",
					payload: { message: "error" },
				})
			}
			default: {
				removeLCS(action)

				toastr.success(I18n.t("transfer.editRcpt.removed"))
				yield put({
					type: "transfer/setLAR",
					payload: { message: "success" },
				})

				if (action.payload.type === "transfer") {
					let comp = yield select(
						(state) => state.transfer.data?.[0]?.comp,
					)

					if (comp === "Transfer") {
						yield put({
							type: "LT_GET_ACTIVE_TRANSFERS_SAGA",
							payload: { justRefresh: true, retriveActive: true },
						})
					}
				} else {
					yield put({ type: "transfer/updateActive", payload: data })
				}
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* changeReceiptRights(action) {
	// Register the saga execution and display the loader.
	addLCS(action)

	yield put({
		type: "action/setActionLoader",
		payload: { transparency: true },
	})
	yield put({ type: "transfer/setLAR" })

	// Setup the request
	const xLsT = yield select((state) => state.auth.xLsToken)
	const token = yield select((state) => state.auth.token.token)

	const payload = {
		token,
		xLsT,
		data: {
			userId: action.payload.id,
			hasRight: action.payload.isManager,
			depositBoxId: action.payload.depositBoxId,
		},
	}

	// Run the service.
	const data = yield call(Api.changeReceiptRights, payload)

	// Hide the loader before anything else.
	yield put({ type: "action/setActionLoader" })

	// Depending on the fetch result, execute one of the following.
	switch (data) {
		case "sessionExpired":
			return yield put({ type: "auth/showPadlock" })
		case "sessionDestroyed":
			return yield put({ type: "auth/sessionExpired" })
		case true: {
			removeLCS(action)

			return yield put({
				type: "transfer/setLAR",
				payload: { message: "error" },
			})
		}
		default: {
			// If the user just removed its own authorizations, close the popup.
			const currentUser = yield select(
				(state) => state.auth.token.userSession.userEmail,
			)
			if (
				action.payload.email === currentUser &&
				!action.payload.isManager
			)
				yield put({ type: "action/closeAction" })
			yield put({
				type: "transfer/setLAR",
				payload: { message: "success" },
			})
			return yield put({ type: "transfer/updateActive", payload: data })
		}
	}
}

export function* getActiveDeposits(action) {
	try {
		addLCS(action)
		if (backwardCompApi("1.20.16")) {
			yield put({ type: "transfer/setCR", payload: true })
			yield put({ type: "transfer/setLoader", payload: true })
		} else {
			yield put({ type: "transfer/setCR" })
		}

		const ogP = { ...action.payload }
		let transferState = yield select((state) => state.transfer)

		if (transferState.searchVal.charAt(0) === "#") {
			transferState = {
				...transferState,
				searchVal: transferState.searchVal.substring(1),
			}
		}

		if (!ogP.search) {
			if (
				transferState.pageSize &&
				!action.payload.limit &&
				action.payload.static
			)
				ogP.limit = transferState.pageSize * 2
			else if (ogP.justRefresh)
				ogP.limit = transferState.pagin.page * transferState.pagin.limit
			//s.data[0].files.length
			else ogP.limit = transferState.pageSize
		}
		//yield put({ type: "transfer/setLAR"})

		if (!ogP?.justRefresh && !ogP?.offset)
			yield put({ type: "transfer/toggleLoading" })
		if (ogP.offset)
			yield put({ type: "transfer/paginLoading", payload: true })

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = {
			token,
			xLsT,
			offset: ogP.offset,
			search: transferState.searchVal
				? transferState.searchVal
				: ogP.search,
			limit: ogP.limit,
		}

		const ogData = yield call(Api.getActiveDeposits, payload)
		if (ogP.offset) yield put({ type: "transfer/paginLoading" })
		yield put({ type: "transfer/setCR" })

		switch (ogData) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				let data = { ...ogData }
				if (
					transferState.pageSize &&
					(!action.payload.limit || ogP.justRefresh)
				) {
					let sps = transferState.pageSize
					data.pages = Math.ceil(ogData.total / sps)
					data.page = Math.floor(ogP.limit / sps)
					data.limit = sps
				}

				if (ogP.offset && !ogP.justRefresh)
					yield put({
						type: "transfer/addElements",
						payload: { ...ogP, ...data },
					})
				else if (ogP.search?.length >= 0)
					yield put({
						type: "transfer/initSearch",
						payload: { ...ogP, ...data },
					})
				else
					yield put({
						type: "transfer/enterInFolder",
						payload: { ...ogP, ...data },
					})

				return yield put({ type: "transfer/setCR" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getArchiveDeposits(action) {
	try {
		addLCS(action)
		yield put({ type: "transfer/setCR", payload: true })
		//yield put({ type: "transfer/setLAR"})

		const ogP = { ...action.payload }
		let s = yield select((state) => state.transfer)

		if (s.pageSize && !action.payload.limit && action.payload.static)
			ogP.limit = s.pageSize * 2
		else if (ogP.justRefresh)
			ogP.limit = s.pagin.page * s.pagin.limit //s.data[0].files.length
		else ogP.limit = s.pageSize

		if (!ogP?.justRefresh && !ogP?.offset)
			yield put({ type: "transfer/toggleLoading" })
		if (ogP.offset)
			yield put({ type: "transfer/paginLoading", payload: true })

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = {
			token,
			xLsT,
			offset: ogP.offset,
			search: ogP.search,
			limit: ogP.limit,
		}

		const ogData = yield call(Api.getArchiveDeposits, payload)
		if (ogP.offset) yield put({ type: "transfer/paginLoading" })

		switch (ogData) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				let data = { ...ogData }
				if (s.pageSize && (!action.payload.limit || ogP.justRefresh)) {
					let sps = s.pageSize
					data.pages = Math.ceil(ogData.total / sps)
					data.page = Math.floor(ogP.limit / sps)
					data.limit = sps
				}

				if (ogP.offset && !ogP.justRefresh)
					yield put({
						type: "transfer/addElements",
						payload: { ...ogP, ...data },
					})
				else if (ogP.search?.length >= 0)
					yield put({
						type: "transfer/initSearch",
						payload: { ...ogP, ...data },
					})
				else
					yield put({
						type: "transfer/enterInFolder",
						payload: { ...ogP, ...data },
					})

				return yield put({ type: "transfer/setCR" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getSharedDeposits(action) {
	try {
		addLCS(action)
		//yield put({ type: "transfer/setLAR"})
		if (backwardCompApi("1.20.16")) {
			yield put({ type: "transfer/setCR", payload: true })
		}

		const ogP = { ...action.payload }
		let s = yield select((state) => state.transfer)

		if (s.pageSize && !action.payload.limit && action.payload.static)
			ogP.limit = s.pageSize * 2
		else if (ogP.justRefresh)
			ogP.limit = s.pagin.page * s.pagin.limit //s.data[0].files.length
		else ogP.limit = s.pageSize

		if (!ogP?.justRefresh && !ogP?.offset)
			yield put({ type: "transfer/toggleLoading" })
		if (ogP.offset)
			yield put({ type: "transfer/paginLoading", payload: true })

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = {
			token,
			xLsT,
			offset: ogP.offset,
			search: ogP.search,
			limit: ogP.limit,
		}

		const ogData = yield call(Api.getSharedDeposits, payload)
		if (ogP.offset) yield put({ type: "transfer/paginLoading" })

		switch (ogData) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return
			default: {
				removeLCS(action)

				let data = { ...ogData }
				if (s.pageSize && (!action.payload.limit || ogP.justRefresh)) {
					let sps = s.pageSize
					data.pages = Math.ceil(ogData.total / sps)
					data.page = Math.floor(ogP.limit / sps)
					data.limit = sps
				}

				if (ogP.offset && !ogP.justRefresh)
					yield put({
						type: "transfer/addElements",
						payload: { ...ogP, ...data },
					})
				else if (ogP.search?.length >= 0)
					yield put({
						type: "transfer/initSearch",
						payload: { ...ogP, ...data },
					})
				else
					yield put({
						type: "transfer/enterInFolder",
						payload: { ...ogP, ...data },
					})

				return yield put({ type: "transfer/setCR" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* postDeposit(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})
		yield put({ type: "transfer/setLAR" })

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = { token, xLsT, action: action.payload }

		const data = yield call(Api.postDeposit, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				let comp = yield select(
					(state) => state.transfer.data?.[0]?.comp,
				)
				toastr.success(I18n.t("transfer.aDeposit.addDepositSuccess"))

				yield put({
					type: "transfer/setLAR",
					payload: { message: "success", id: data.id },
				})

				if (comp === "Deposit")
					yield put({
						type: "LT_GET_ACTIVE_DEPOSITS_SAGA",
						payload: { justRefresh: true },
					})

				return //yield put({ type: 'action/closeAction' })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* postOpenDeposit(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})
		yield put({ type: "transfer/setLAR" })

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = { token, xLsT, action: action.payload }

		const data = yield call(Api.postOpenDeposit, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				let comp = yield select(
					(state) => state.transfer.data?.[0]?.comp,
				)
				toastr.success(I18n.t("transfer.aDeposit.addDepositSuccess"))

				yield put({
					type: "transfer/setLAR",
					payload: { message: "success", id: data.id },
				})

				if (comp === "Deposit")
					yield put({
						type: "LT_GET_ACTIVE_DEPOSITS_SAGA",
						payload: { justRefresh: true },
					})

				return
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* addFilesToDeposit(action) {
	try {
		const sagaPayload = { ...action.payload }
		const isLastFile = sagaPayload.isLast
		yield put({ type: "transfer/setLAR" })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		const token = yield select((state) => state.auth.token.token)
		const xLsT = yield select((state) => state.auth.xLsToken)

		let chunkSize = 20000000 // bytes
		let d = { ...action.payload.data }
		const payload = { token, xLsT }

		d.chunkIndex = 0
		d.totalChunkCount = Math.ceil(d.file.size / chunkSize)
		d.uuid = uuidv4()
		const fileId = yield call(generateFileHashWithoutContent, d.file)

		let ret = true

		const uploadBlocked = yield select(
			(state) => state.transfer.stopFilesUpload,
		)

		if (uploadBlocked) {
			d.chunkIndex = d.totalChunkCount
		}

		while (d.chunkIndex < d.totalChunkCount) {
			// If the upload was blocked (this happens when the token has already expired when making
			// the first request), don't do anything except restauring the blocked state if we're
			// treating the last file of the upload, we don't want the user to stay blocked.
			const uploadBlocked = yield select(
				(state) => state.transfer.stopFilesUpload,
			)
			if (uploadBlocked) {
				if (isLastFile) {
					yield put({ type: "action/setActionLoader" })
					yield put({ type: "transfer/setLoader" })
					yield put({ type: "transfer/unblockFilesUpload" })
				}

				return
			}

			let offset = d.chunkIndex * chunkSize
			let blob = d.file.slice(offset, offset + chunkSize)
			d.filePart = new File([blob], d.file.name)

			yield put({
				type: "action/startUpload",
				payload: {
					fileId,
				},
			})

			ret = yield call(Api.addFilesToDeposit, {
				...payload,
				data: {
					...d,
					firstUpload: sagaPayload.multiPart === 0 ? "true" : "false",
				},
			})

			yield put({
				type: "action/endUpload",
				payload: {
					fileId,
				},
			})

			const checkData =
				ret !== "sessionExpired" &&
				ret !== "sessionDestroyed" &&
				ret !== true

			if (checkData) {
				if (ret.uncertainChunk) {
					yield put({
						type: "transfer/addUncertainFile",
						payload: d.file.name,
					})
				}
			} else {
				yield put({ type: "transfer/blockFilesUpload" })
				d.chunkIndex = d.totalChunkCount
			}

			yield put({ type: "action/closeAction" })

			d.chunkIndex++
		}

		if (isLastFile) {
			yield put({ type: "action/setActionLoader" })

			// At the last file, unblock the files upload to allow the user other tries at uploading.
			yield put({ type: "transfer/unblockFilesUpload" })
		}

		switch (ret) {
			case "sessionExpired":
				yield put({ type: "action/cancelUploadEstimation" })
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				yield put({ type: "action/cancelUploadEstimation" })
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return yield put({ type: "action/cancelUploadEstimation" })
			default: {
				if (
					action.payload.multiTotal ===
					action.payload.multiPart + 1
				) {
					yield put({ type: "action/closeAction" })
					yield put({ type: "transfer/setUploadLoading" })

					const uncertainFiles = yield select(
						(state) => state.transfer.uncertainFiles,
					)
					if (uncertainFiles) {
						toastr.success(
							I18n.t("transfer.aDeposit.successUploadWarn"),
						)
						yield put({ type: "transfer/removeUncertainFiles" })
					} else {
						toastr.success(
							I18n.t("transfer.aDeposit.successUpload"),
						)
					}

					yield put({ type: "transfer/selectById", payload: ret.id })
					return yield put({
						type: "transfer/updateNewActive",
						payload: ret,
					})
				}
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* deleteFileFromDeposit(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = { token, xLsT, action: action.payload }

		const data = yield call(Api.deleteFileFromDeposit, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				toastr.success(
					I18n.t("transfer.aDeposit.delFileFromDepositSuccess"),
				)

				yield put({
					type: "transfer/setLAR",
					payload: { message: "success" },
				})
				return yield put({
					type: "transfer/updateActive",
					payload: data,
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* putDeposit(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)

		const payload = { token, xLsT, ...action.payload }
		const data = yield call(Api.putDeposit, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				toastr.success(I18n.t("editSuccess"))
				yield put({ type: "action/closeAction" })
				yield put({ type: "action/closeMiniAction" })
				yield put({ type: "transfer/updateActive", payload: data })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* deleteDeposit(action) {
	try {
		addLCS(action)

		yield put({ type: "transfer/setCR", payload: true })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = { token, xLsT, action: action.payload }

		if (action.payload.length > 1) {
			yield put({ type: "transfer/toggleLoading" })
			yield put({ type: "action/closeMiniAction" })
		}

		const data = yield call(Api.deleteDeposit, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				yield put({ type: "transfer/setCR" })
				return removeLCS(action)
			default: {
				removeLCS(action)

				let comp = yield select(
					(state) => state.transfer.data?.[0]?.comp,
				)
				toastr.success(I18n.t("transfer.delDepositSuccess"))

				if (action.payload.length > 1)
					yield put({ type: "transfer/toggleLoading" })

				yield put({ type: "action/setLAR", payload: "navigateBack" })

				if (
					comp === "Deposit" &&
					action?.requiresRedirectInWindowHistoryState
				) {
					yield put({
						type: "LT_GET_ACTIVE_DEPOSITS_SAGA",
						payload: { justRefresh: true },
					})
				} else if (!action?.requiresRedirectInWindowHistoryState) {
					yield put({ type: "transfer/setCR" })
				}

				return yield put({ type: "action/closeMiniAction" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* downloadFileFromDeposit(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		yield put({ type: "transfer/setLAR" })

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = { token, xLsT, action: action.payload }

		let data = yield call(Api.downloadFileFromDeposit, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			default: {
				removeLCS(action)

				if (action.payload.isV2) {
					yield put({
						type: "action/setActionLoader",
						payload: { longMsg: true },
					})
				} else {
					yield put({ type: "action/setActionLoader" })
				}

				return yield put({
					type: "transfer/setLAR",
					payload: { message: "success" },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getContacts(action) {
	try {
		addLCS(action)
		yield put({ type: "transfer/setCR", payload: true })

		if (!action.payload?.justRefresh && !action.payload?.offset)
			yield put({ type: "transfer/toggleLoading" })
		if (action.payload.offset)
			yield put({ type: "transfer/paginLoading", payload: true })

		const ogP = { ...action.payload }
		let s = yield select((state) => state.transfer)

		if (!ogP.search) {
			if (s.pageSize && !action.payload.limit && action.payload.static)
				ogP.limit = s.pageSize * 2
			else if (ogP.justRefresh) ogP.limit = s.pagin.page * s.pagin.limit
			//s.data[0].files.length
			else ogP.limit = s.pageSize
		}

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = {
			token,
			xLsT,
			offset: ogP.offset,
			search: s.searchVal ? s.searchVal : ogP.search,
			limit: ogP.limit,
		}

		const ogData = yield call(Api.getContacts, payload)

		yield put({ type: "transfer/setCR" })
		if (ogP.offset) yield put({ type: "transfer/paginLoading" })

		switch (ogData) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return
			default: {
				removeLCS(action)

				let data = { ...ogData }

				if (s.pageSize && !action.payload.limit) {
					let sps = s.pageSize
					data.pages = Math.ceil(ogData.total / sps)
					data.page = Math.floor(ogP.limit / sps)
					data.limit = sps
				}

				if (ogP.offset && !ogP.justRefresh)
					yield put({
						type: "transfer/addElements",
						payload: { ...ogP, ...data },
					})
				else if (ogP.search?.length >= 0)
					yield put({
						type: "transfer/initSearch",
						payload: { ...ogP, ...data },
					})
				else
					yield put({
						type: "transfer/enterInFolder",
						payload: { ...ogP, ...data },
					})

				return yield put({ type: "transfer/setCR" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* postContact(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = { token, xLsT, action: action.payload }

		const data = yield call(Api.postContact, payload)
		////console.log(data)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return
			default: {
				removeLCS(action)

				let comp = yield select(
					(state) => state.transfer.data?.[0]?.comp,
				)
				toastr.success(I18n.t("transfer.addContactSuccess"))

				if (comp === "Contact")
					yield put({
						type: "LT_GET_CONTACTS_SAGA",
						payload: { justRefresh: true },
					})

				return yield put({ type: "action/closeAction" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* putContact(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)

		//console.log(action.payload)
		let phone = ""
		if (action.payload.phoneNumber) {
			phone = action.payload.phoneNumber.replace(/ /g, "")
			let match

			if (match === /0([0-9]{9})/.exec(phone)) {
				phone = "+33" + match[1]
			}
		}

		const payload = {
			token,
			xLsT,
			action: { ...action.payload, phoneNumber: phone },
		}

		const data = yield call(Api.putContact, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return
			default: {
				removeLCS(action)

				toastr.success(I18n.t("editSuccess"))
				yield put({ type: "action/closeAction" })
				yield put({ type: "transfer/updateActive", payload: data })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* deleteContact(action) {
	try {
		addLCS(action)

		yield put({ type: "transfer/setCR", payload: true })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = { token, xLsT, action: action.payload }

		const data = yield call(Api.deleteContact, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return yield put({ type: "transfer/setCR" })
			default: {
				removeLCS(action)

				let comp = yield select(
					(state) => state.transfer.data?.[0]?.comp,
				)
				toastr.success(I18n.t("transfer.delContactSuccess"))

				yield put({ type: "action/setLAR", payload: "navigateBack" })

				if (
					comp === "Contact" &&
					action?.requiresRedirectInWindowHistoryState
				) {
					yield put({
						type: "LT_GET_CONTACTS_SAGA",
						payload: { justRefresh: true },
					})
				} else if (!action?.requiresRedirectInWindowHistoryState) {
					yield put({ type: "transfer/setCR" })
				}

				return yield put({ type: "action/closeMiniAction" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* manageSelectedTransfer(action) {
	yield put({ type: "transfer/setCR", payload: true })
	yield put({
		type: "transfer/manageSelected",
		payload: { ...action.payload, elemKey: action.payload?.id },
	})
	yield put({ type: "transfer/setCR" })
}

export default function* transferSagas() {
	yield takeLeading("LT_GET_ACTIVE_TRANSFERS_SAGA", getActiveTransfers)
	yield takeLeading("LT_GET_ARCHIVE_TRANSFERS_SAGA", getArchiveTransfers)
	//yield takeLeading("LT_POST_PASSWORD_TRANSFERS_SAGA", postPasswordTransfer)
	//yield takeLeading("LT_POST_EMAIL_TRANSFERS_SAGA", postEmailTransfer)
	yield takeLeading("LT_PUT_TRANSFER_STATUS_SAGA", putTransferStatus)
	yield takeEvery("LT_SHARE_PW_TRANSFER_SAGA", sharePwTransfer)
	yield takeLeading("LT_DELETE_TRANSFER_SAGA", deleteTransfer)

	yield takeLeading("LT_ADD_RECEIPTS_SAGA", addReceipts)
	yield takeLeading("LT_RESEND_toRECEIPTS_SAGA", resendToReceipts)
	yield takeLeading("LT_REMOVE_RECEIPTS_SAGA", removeReceipt)
	yield takeLeading("LT_CHANGE_RECEIPTS_RIGHTS_SAGA", changeReceiptRights)

	yield takeLeading("LT_GET_ACTIVE_DEPOSITS_SAGA", getActiveDeposits)
	yield takeLeading("LT_GET_ARCHIVE_DEPOSITS_SAGA", getArchiveDeposits)
	yield takeLeading("LT_POST_DEPOSIT_SAGA", postDeposit)
	yield takeLeading("LT_POST_OPEN_DEPOSIT_SAGA", postOpenDeposit)
	//yield takeLeading("LT_UPLOAD_FILES_DEPOSIT_SAGA", addFilesToDeposit)
	//yield takeLeading("LT_UPLOAD_FILES_NEW_DEPOSIT_SAGA", addFilesToNewDeposit)
	yield takeLeading("LT_DELETE_FILE_DEPOSIT_SAGA", deleteFileFromDeposit)
	yield takeLeading("LT_DOWNLOAD_FROM_DEPOSIT_SAGA", downloadFileFromDeposit)

	yield takeLeading("LT_PUT_DEPOSIT_SAGA", putDeposit)
	yield takeLeading("LT_GET_SHARED_DEPOSITS_SAGA", getSharedDeposits)
	yield takeLeading("LT_DELETE_DEPOSIT_SAGA", deleteDeposit)

	yield takeLeading("LT_GET_CONTACTS_SAGA", getContacts)
	yield takeLeading("LT_POST_CONTACT_SAGA", postContact)
	yield takeLeading("LT_PUT_CONTACT_SAGA", putContact)
	yield takeLeading("LT_DELETE_CONTACT_SAGA", deleteContact)

	/* ------------------------- LOCKTRANSFER PERMALINKS ------------------------ */
	yield takeLeading("LT_MANAGE_SELECTED_SAGA", manageSelectedTransfer)
	/* ----------------------- END LOCKTRANSFER PERMALINKS ---------------------- */

	const channel = yield actionChannel("LT_WITH_BUFFER_SAGA")

	while (true) {
		const payload = yield take(channel)

		switch (payload.payload.channelMod) {
			case "uploadToDeposit": {
				yield call(addFilesToDeposit, payload)
				break
			}
			case "postTransfer": {
				yield call(postTransfer, payload)
				break
			}

			default:
				return null
		}
	}
}
